import { PageJsonSnippetObj } from '~/global.types';

// We don't need Vite to warn this as it is deliberate dynamic import to an external url
export const getStudioHydrator = (cname: string) =>
  import(
    /* @vite-ignore */ `https://${cname.replace('*', 'studiohydrator')}/rendering/page/page.mjs`
  );

const cname =
  import.meta.env?.VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME ?? '*.test.upflowyexperience.com';

const devHydratorAccessCname = import.meta.env?.VITE_DEV_HYDRATOR_ACCESS_DOMAIN_NAME;

const devComponentLibraryCname = import.meta.env?.VITE_DEV_COMPONENT_LIBRARY_DOMAIN_NAME;

const devSiteProvisioningCname = import.meta.env?.VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME;

const renderPage = async (
  pageId = '',
  workspaceId = '',
  data: PageJsonSnippetObj,
): Promise<string> => {
  if (!data) {
    return '';
  }

  let studioHydrator;
  try {
    const { default: pageHTML } = await getStudioHydrator(devHydratorAccessCname || cname);
    studioHydrator = pageHTML;
  } catch (error) {
    console.error('Error loading studio hydrator:', error);
    return `<code>${error}</code>`;
  }

  let htmlStr;

  try {
    const dataCopyString = JSON.stringify(data);

    htmlStr = studioHydrator(
      dataCopyString,
      `https://${(devSiteProvisioningCname || cname).replace(
        '*',
        workspaceId,
      )}/${workspaceId}/${pageId}`,
      `https://${(devComponentLibraryCname || cname).replace('*', 'components')}/`,
    );
  } catch (e) {
    console.error('Error generating HTML:', e);
    return `<code>${e}</code>`;
  }

  return htmlStr;
};

export default renderPage;
